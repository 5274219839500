import { Box } from '@mui/material';
import { JumpsType } from './';

export const Jumps = ({
    jumps,
}: JumpsType) => {
    return (
        <Box sx={{ margin: '20px', pointerEvents: 'none' }}>
            <h5>Jump to:</h5>
            <Box component="select" sx={{ minWidth: '250px' }}>
                {jumps && jumps.map((jump) => (
                    <option>{jump.text}</option>
                ))}
            </Box>
        </Box>
    );
};