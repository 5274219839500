import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    maxLength,
    regex,
    email,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { offices } from './';

const Title = ({ record }: TitleProps) => {
    return <span>Staff {record ? `"${record.name}"` : ''}</span>;
};

export const StaffEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <SelectInput source="office" choices={offices} validate={required()} />
            <TextInput source="name" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="firstName" label="First Name" resettable validate={[maxLength(120), required()]} fullWidth />
            <TextInput source="lastName" label="Last Name" resettable validate={[maxLength(120), required()]} fullWidth />
            <TextInput source="title" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="description" resettable validate={maxLength(500)} multiline fullWidth />
            <TextInput source="emailAddress" label="Email Address" resettable validate={[maxLength(250), email()]} fullWidth />
            <TextInput source="phoneNumber" label="Phone Number" resettable validate={[maxLength(50), regex(/^\d{3}-\d{3}-\d{4}$/, 'Please format with dashes. Example: 803-123-4567')]} placeholder="###-###-####" />
        </SimpleForm>
    </Edit>
);