import settings from '../settings'

export const normalizeUrl = (url: string) => {
    if (!url)
        return '';

    // Do some custom URL conversion
    const protocolRegex = /(^[a-z]*:\/\/)/
    let hasProtocol = protocolRegex.test(url);
    if (!hasProtocol) {
        const domainRegex = /^((?:[\w-]+\.)+[\w-]+)/;
        const hasDomain = domainRegex.test(url);
        if (hasDomain) {
            url = 'https://' + url;
            hasProtocol = true;
        } else {
            //if (!url.startsWith('/'))
            //url = '/' + url;
        }
    }
    if (hasProtocol) {
        try {
            const { host, pathname, searchParams } = new URL(url);
            if (settings.linkExcludeDomains.test(host)) {
                // use relative url (just path & parameters)
                url = pathname + searchParams;
            }
        }
        catch {
            console.warn('Unable to convert link: ', url);
        }
    }

    return url;
}

export const extractYoutubeId = (url: string) => {
    const re = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim
    const matches = re.exec(url);
    if (matches && matches.length > 3) {
        return matches[3];
    }
    return '';
}

export const updateQueryStringParameter = (uri: string, key: string, value: string | number) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}