import { useRecordContext } from 'react-admin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import settings from '../../settings';

export const LinkField = ({ source, pathPrefix = '' }: { source: any; pathPrefix?: string; }) => {
    const record = useRecordContext();
    const url = record && settings.publicSite + pathPrefix + record[source];
    return (
        <div style={{width: '100%', textAlign: 'end' }}>
            {record[source]
                ? <a href={url} style={{ color: 'greenyellow' }} target="_blank" rel="noopener">{url} <OpenInNewIcon style={{ height: 20, marginBottom: 2 }} /></a>
                : <span>&nbsp;</span>
            }
        </div>
    );
};