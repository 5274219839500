import { SponsorList } from './SponsorList';
import { SponsorCreate } from './SponsorCreate';
import { SponsorEdit } from './SponsorEdit';

export default {
    list: SponsorList,
    create: SponsorCreate,
    edit: SponsorEdit,
};

export const levels = [
    { id: 'Palmetto', name: 'Palmetto' },
    { id: 'Patron', name: 'Patron' },
    { id: 'Investor', name: 'Investor' },
    { id: 'Partner', name: 'Partner' },
    { id: 'Associate', name: 'Associate' },
];