import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export const ImageMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('hero')}>
        <ListItemIcon>
            <PhotoCameraIcon />
        </ListItemIcon>
        <ListItemText primary="Image" />
    </ListItemButton>
);