import { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Box,
} from '@mui/material';
import { extractYoutubeId } from '../../utilities';

export const VideoDialog = ({
    open,
    handleClose,
    handleSubmit,
}: {
    open: boolean;
    handleClose: any;
    handleSubmit: any;
}) => {
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [message, setMessage] = useState('');

    const customHandleSubmit = () => {
        const youtubeId = extractYoutubeId(youtubeUrl);
        if (youtubeId) {
            handleSubmit(youtubeId);
        } else {
            setMessage('Invalid YouTube Link');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
            <DialogTitle id="form-dialog-title" sx={{ paddingBottom: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Video</span>
                </Box>
            </DialogTitle>
            <Box component={DialogContent} sx={{
                paddingTop: 0,
                paddingBottom: '12px',
                paddingLeft: '24px',
                paddingRight: '24px',
            }}>
                <Box component="form" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                }}>
                    <Box component={TextField} sx={{ width: '100%' }} label="YouTube Link" variant="filled" value={youtubeUrl} onChange={(e: any) => setYoutubeUrl(e.target.value)} />
                </Box>
                <Box component="p" sx={{
                    minHeight: '42px',
                    padding: '10px',
                    fontSize: '14px',
                    textAlign: 'center',
                    color: 'red',
                }}>
                    {message}
                </Box>
            </Box>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '25px',
                paddingRight: '25px',
            }}>
                <Button onClick={customHandleSubmit} disabled={!youtubeUrl} variant="contained" color="primary">
                    Submit
                </Button>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};