import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ShortTextIcon from '@mui/icons-material/ShortText';

export const HeadingMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('heading')}>
        <ListItemIcon>
            <ShortTextIcon />
        </ListItemIcon>
        <ListItemText primary="Heading" />
    </ListItemButton>
);