import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer,
    required,
    email,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';

const roles = [
    { id: '', name: 'Default User' },
    { id: 'Administrator', name: 'Administrator' },
];

const Title = ({ record }: TitleProps) => {
    return <span>User {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>;
};

export const UserEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <TextInput source="firstName" label="First Name" resettable validate={required()} fullWidth />
            <TextInput source="lastName" label="Last Name" resettable validate={required()} fullWidth />
            <TextInput source="email" label="Email Address" resettable validate={[required(), email()]} fullWidth />
            <SelectInput source="role" choices={roles} />
            <FormDataConsumer>
                {({ formData,...rest}) => (
                    <BooleanInput source="sendPasswordReset" label={formData['hasPassword'] ? 'Send Password Reset' : 'Resend Invitation'} defaultValue={false} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);