import { ArrayInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import { WidenImageInput } from '../widen';
import { normalizeUrl } from '../../utilities';

export const CardsEdit = () => (
    <>
        <ArrayInput source="cards">
            <SimpleFormIterator>
                <WidenImageInput source="image" label="Image" width="300" validate={required()} />
                <TextInput source="subtitle" label="Subtitle" resettable fullWidth />
                <TextInput source="title" label="Title" resettable fullWidth />
                <TextInput source="description" label="Description" multiline resettable fullWidth />
                <TextInput source="link" label="Link" resettable fullWidth validate={required()} parse={(x: string) => normalizeUrl(x)} />
            </SimpleFormIterator>
        </ArrayInput>
    </>
);