import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';
import { RichTextInput } from '../rich-text';

export const AccordianEdit = () => (
    <>
        <ArrayInput source="accordian">
            <SimpleFormIterator>
                <TextInput source="title" label="Title" resettable fullWidth />
                <RichTextInput source="richText" autoresize={false} label="" />
            </SimpleFormIterator>
        </ArrayInput>
    </>
);