import { Box } from '@mui/material';
import { CardsType, Card } from './';

export const Cards = ({
    cards
}: CardsType) => {
    return (
        <Box sx={{
            display: 'grid',
            gridGap: '20px',
            padding: '20px',
            maxWidth: '1200px',
            '@media(min-width: 1100px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@media(min-width: 1300px)': {
                gridTemplateColumns: 'repeat(3, 1fr)',
            },
        }}>
            {cards && cards.map((card, index) => (
                <Card {...card} key={card.id} />
            ))}
        </Box>
    );
};