import { Box } from '@mui/material';
import { HeadingType } from './';

export const Heading = ({
    title,
    description,
}: HeadingType) => (
    <Box sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '20px'
    }}>
        <h1>{title}</h1>
        <p>{description}</p>
    </Box>
);