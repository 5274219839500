import {
    ListProps,
    FilterProps,
    List,
    Datagrid,
    TextField,
    EditButton,
    SelectInput,
    SearchInput,
    Filter,
} from 'react-admin';

const levels = [
    { id: 'Palmetto', name: 'Palmetto' },
    { id: 'Patron', name: 'Patron' },
    { id: 'Investor', name: 'Investor' },
    { id: 'Partner', name: 'Partner' },
    { id: 'Associate', name: 'Associate' },
];

const ListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="level" label="Level" choices={levels} />
    </Filter>
);

export const SponsorList = (props: ListProps) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="level" />
            <EditButton />
        </Datagrid>
    </List>
);