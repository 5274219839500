import { useRef, useState, useEffect } from 'react';
import { Confirm } from 'react-admin';
import { Box } from '@mui/material';
import { DragDropContext, OnDragEndResponder, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { AccordianItemType } from '../accordian';
import { CardType } from '../cards';
import { JumpType } from '../jumps';
import { ResourceType } from '../resources';
import { VideoType } from '../videos';
import {
    ContentType,
    ContentItem,
    ContentAddButton,
    useDialog,
    useDrawer,
} from './';

export const ContentList = ({
    data,
    onChange,
    touched,
    type,
}: {
    data: string;
    onChange: (event: any) => void;
    touched: boolean;
    type?: string;
}) => {

    const activeIdRef = useRef<string | undefined>(undefined);
    const [contentList, setContentList] = useState<ContentType[]>([]);
    const [openDialog, closeDialog] = useDialog();
    const [openDrawer, closeDrawer] = useDrawer();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const onConfirmDeleteClose = () => setConfirmOpen(false);

    useEffect(() => {
        if (data) {
            try {
                setContentList(JSON.parse(data));
            }
            catch (error) {
                console.log('ERROR', error);
            }
        }
    }, [data]);

    const customOnChange = (newContent: ContentType) => {
        if (contentList.length > 0) {
            const index = contentList.findIndex((item => item.id === newContent.id));
            const newContentList = [...contentList.slice(0, index), newContent, ...contentList.slice(index + 1)];
            setContentList(newContentList);
            onChange(JSON.stringify(newContentList));
        }
    }

    const onAddClick = (id?: string) => {
        openDrawer(onMenuClick, id);
    };

    const onEditClick = (content: ContentType) => {
        customOpenDialog(content);
    };

    const onDeleteClick = (id: string) => {
        activeIdRef.current = id;
        setConfirmOpen(true);
    };

    const onConfirmDelete = () => {
        if (activeIdRef.current) {
            Delete(activeIdRef.current);
            setConfirmOpen(false);
        }
    };

    const onMenuClick = (content: ContentType, activeId?: string) => {
        activeIdRef.current = activeId;
        customOpenDialog(content);
    };

    const customOpenDialog = (content: ContentType) => {
        let options = { disableEnforceFocus: true, maxWidth: 'sm', fullScreen: false };
        switch (content.type) {
            case 'accordian':
                options.maxWidth = 'xl';
                break;
            case 'resources':
                options.maxWidth = 'md';
                break;
            case 'rich-text':
                options.fullScreen = true;
                break;
            default:
                break;
        }

        openDialog({
            content,
            onSave,
            options,
        });
    };

    const onSave = (data: Partial<any>) => {
        Promise.resolve(data).then(data => {
            let content = data as ContentType;
            // Workaround for SimpleFormIterator's missing id and type
            switch (content.type) {
                case 'accordian':
                    content.accordian = content.accordian.map((accordianItem: AccordianItemType) => ({ ...accordianItem, id: uuid(), type: 'accordian-item' }));
                    break;
                case 'cards':
                    content.cards = content.cards.map((card: CardType) => ({ ...card, id: uuid(), type: 'card' }));
                    break;
                case 'jumps':
                    content.jumps = content.jumps.map((jump: JumpType) => ({ ...jump, id: uuid(), type: 'jump' }));
                    break;
                case 'resources':
                    content.resources = content.resources.map((resource: ResourceType) => ({ ...resource, id: uuid(), type: 'resource' }));
                    break;
                case 'videos':
                    content.videos = content.videos.map((video: VideoType) => ({ ...video, id: uuid(), type: 'video' }));
                    break;
                default:
                    break;
            }
            // Update if existing id
            if (data.id) {
                Update(content);
            } else {
                // Otherwise add unique id and insert into the list
                content.id = uuid();
                Insert(content);
            }
            activeIdRef.current = undefined;
        });
        closeDialog();
    }

    const Update = (content: ContentType) => {
        const index = contentList.findIndex((item => item.id == content.id));
        if (index !== -1) {
            const newContentList = Object.assign([...contentList], { [index]: content });
            setContentList(newContentList as ContentType[]);
            onChange(JSON.stringify(newContentList));
        }
    };

    const Insert = (newContent: ContentType) => {
        let newContentList;
        if (activeIdRef.current) {
            // Add item immediately after the active item
            const insertIndex = contentList.findIndex((item => item.id == activeIdRef.current));
            newContentList = [...contentList.slice(0, insertIndex + 1), newContent, ...contentList.slice(insertIndex + 1)];
        } else {
            // Otherwise add item to the start of the list
            newContentList = [newContent, ...contentList];
        }
        setContentList(newContentList as ContentType[]);
        onChange(JSON.stringify(newContentList));
    };

    const Delete = (id: string) => {
        if (contentList.length > 0) {
            const index = contentList.findIndex((item => item.id === id));
            const newContentList = [...contentList.slice(0, index), ...contentList.slice(index + 1)];
            setContentList(newContentList);
            onChange(JSON.stringify(newContentList));
            activeIdRef.current = undefined;
        }
    };

    const onDragEnd: OnDragEndResponder = ({ source, destination }) => {
        if (!destination || destination.index === source.index) {
            return;
        }
        const newContentList = [...contentList];
        const [removed] = newContentList.splice(source.index, 1);
        newContentList.splice(destination.index, 0, removed);
        onChange(JSON.stringify(newContentList));
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{
                flex: 1,
                paddingTop: '4px',
                backgroundColor: '#303030',
            }}>
                <Box component={ContentAddButton} onClick={() => onAddClick()} visible={true} />
                <Droppable droppableId="content" direction="vertical">
                    {(droppableProvided, snapshot) => (
                        <Box
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '5px',
                                padding: '5px',
                                paddingBottom: 0,
                            }}
                            className={snapshot.isDraggingOver ? ' isDraggingOver' : ''}
                        >
                            {contentList && contentList.map((content, index) => (
                                <ContentItem
                                    key={String(content.id)}
                                    index={index}
                                    content={content}
                                    onChange={customOnChange}
                                    onAddClick={() => onAddClick(content.id)}
                                    onEditClick={() => onEditClick(content)}
                                    onDeleteClick={() => onDeleteClick(content.id)} children={undefined}>
                                </ContentItem>
                            ))}
                            {droppableProvided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </Box>
            <Confirm
                isOpen={confirmOpen}
                title="Delete"
                content="Are you sure you want to delete this item?"
                onConfirm={onConfirmDelete}
                onClose={onConfirmDeleteClose}
            />
        </DragDropContext>
    );
};