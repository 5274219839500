import { Box } from '@mui/material';
import { VideosType, VideoItem } from './';

export const Videos = ({
    videos
}: VideosType) => {
    return (
        <Box sx={{
            display: 'grid',
            gridGap: '20px',
            padding: '20px',
            maxWidth: '1200px',
            '@media(min-width: 1100px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@media(min-width: 1300px)': {
                gridTemplateColumns: 'repeat(3, 1fr)',
            },
        }}>
            {videos && videos.map((video) => (
                <VideoItem {...video} key={video.id} />
            ))}
        </Box>
    );
};