import {
    ListProps,
    FilterProps,
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    SelectInput,
    SearchInput,
    Filter,
} from 'react-admin';
import {
    types,
    years,
    fiscalYears,
    quarters,
    months,
} from './';

const ListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="type" label="Type" choices={types} />
        <SelectInput source="year" label="Year" choices={years} />
        <SelectInput source="fiscalYear" label="Fiscal Year" choices={fiscalYears} />
        <SelectInput source="quarter" label="Quarter" choices={quarters} />
        <SelectInput source="month" label="Month" choices={months} />
    </Filter>
);

export const ReportList = (props: ListProps) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="title" />
            <TextField source="type" />
            <TextField source="year" />
            <TextField source="fiscalYear" />
            <TextField source="quarter" />
            <TextField source="month" />
            <DateField source="publishDate" sortByOrder="DESC" />
            <EditButton />
        </Datagrid>
    </List>
);