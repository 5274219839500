import { Box } from '@mui/material';
import { AccordianType, AccordianItem } from './';

export const Accordian = ({
    accordian,
}: AccordianType) => {
    return (
        <Box sx={{ padding: '20px' }}>
            {accordian && accordian.map((accordianItem) => (
                <AccordianItem {...accordianItem} key={accordianItem.id} />
            ))}
        </Box>
    );
};