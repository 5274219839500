import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import {
    TextField,
    Button,
    Paper,
    Grid,
    Typography,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import settings from '../../settings';
import { WidenAssetType } from './';

export const WidenSearchDialog = ({
    open,
    handleClose,
    handleSelect,
    assetType,
    assetCategory,
}: {
    open: boolean;
    handleClose: any;
    handleSelect: any;
    assetType: 'image' | 'pdf' | 'video';
    assetCategory?: string;
}) => {
    const dataProvider = useDataProvider();
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState<WidenAssetType | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState('');
    const [count, setCount] = useState<number | undefined>(undefined);
    const [total, setTotal] = useState<number | undefined>(undefined);

    const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
        setResults([]);
        setSelected(undefined);

        const query = `ft:${assetType} ag:{${settings.widenAssetGroup}} cat:${settings.widenCategory} ${search}`;
        const sort = '-last_update_date';
        const limit = 100;
        const offset = 0;
        const expand = 'file_properties,thumbnails';

        try {
            const { data, total } = await dataProvider.getList<any>(
                'assets',
                {
                    filter: { query, sort, limit, offset, expand },
                    sort: { field: '', order: 'ASC' },  // not used
                    pagination: { page: 1, perPage: 100 },  // not used
                }
            );

            if (data) {
                const count = Math.min(limit, total ?? 0);

                if (total && total > 0) {
                    setMessage(`Displaying ${count} of ${total} results`);
                } else {
                    setMessage(`No results found`);
                }

                setTotal(total);
                setCount(count);
                //@ts-ignore
                setResults(data.map((item: any) => {
                    const date = new Date(item.last_update_date).toLocaleString();
                    return {
                        id: item.id,
                        externalId: item.external_id,
                        thumbnail: item.thumbnails['300px'].url,
                        filename: item.filename,
                        date: date,
                        format: item.file_properties.format,
                        height: item.file_properties.image_properties?.height,
                        width: item.file_properties.image_properties?.width,
                    }
                }));
            }
        }
        catch { }

        setLoading(false);
    };

    const customHandleSelect = () => {
        if (selected) {
            switch (assetType) {
                case 'image':
                    handleSelect({
                        externalId: selected.externalId,
                        filename: selected.filename,
                        // format: selected.format, TODO: Add after testing on front end
                        // caption: selected.caption, TODO: Add after testing on front end
                    });
                    break;
                case 'pdf':
                    handleSelect({
                        externalId: selected.externalId,
                        filename: selected.filename,
                        // format: selected.format, TODO: Add after testing on front end
                    });
                    break;
                case 'video':
                    break;
                default:
                    console.log(`ERROR: Unexpected asset type request!`);
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
            <DialogTitle id="form-dialog-title" sx={{ paddingBottom: 0, backgroundColor: '#424242' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Asset Search</span>
                    <span>&nbsp;</span>
                    <Box component="a" sx={{ color: '#2196f3' }} href="https://scprt.widencollective.com/" target="_blank" rel="nopener">
                        Widen Collective<OpenInNewIcon sx={{ height: 20, marginBottom: '4px' }} />
                    </Box>
                </Box>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '10px',
                    }}
                    onSubmit={(event: any) => handleSearch(event)}>
                    <TextField sx={{ width: '100%' }} label="Search" variant="filled" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Button sx={{ marginLeft: '10px' }} type="submit" variant="contained" color="primary" size="medium" disabled={!search}>
                        Search
                    </Button>
                </Box>
                <Box
                    sx={{
                        minHeight: 42,
                        padding: '10px',
                        fontSize: '14px',
                        textAlign: 'center',
                    }}>
                    {message}
                </Box>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: 0,
                    margin: 0,
                    backgroundColor: '#424242'
                }}>
                <Grid
                    sx={{
                        minHeight: 300,
                        width: '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        padding: 0,
                        paddingBottom: '8px',
                        backgroundColor: '#21212140',
                    }}
                    container
                    direction="column"
                    justifyContent={loading ? 'center' : 'flex-start'}
                    alignItems="center"
                    spacing={3}
                >
                    {loading &&
                        <CircularProgress />
                    }
                    {results.map((item: any, index: number) => (
                        <Grid
                            item
                            key={item.id}
                            sx={{
                                width: '100%',
                                margin: 0,
                                padding: '8px !important',
                                paddingBottom: '0 !important',
                                cursor: 'pointer',
                            }}
                            onClick={() => setSelected(item)}>
                            <Paper
                                sx={{
                                    marginRight: '8px',
                                    backgroundColor: '#424242',
                                    transition: 'background-color 250ms ease-in-out',
                                    '&:hover': {
                                        backgroundColor: '#303030',
                                        '& $image': {
                                            backgroundColor: '#303030',
                                        }
                                    },
                                }}>
                                <Grid container wrap="nowrap" spacing={2}
                                    sx={{
                                        width: '100%',
                                        margin: '0',
                                        padding: '8px !important',
                                        outline: selected && (item.id === selected.id) ? `#2196f3  2px solid` : undefined,
                                    }}>
                                    <Grid item sx={{ width: 185, alignSelf: 'center', margin: '0 !important', padding: '0 !important' }}>
                                        <Box
                                            component="img"
                                            sx={{
                                                width: 160,
                                                height: 'auto',
                                                transition: 'transform .2s, background-color 250ms ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(2) translate(20%)',
                                                    boxShadow: '5px 5px 20px 0px rgba(0, 0, 0, 0.50)',
                                                },
                                            }}
                                            src={item.thumbnail}
                                            alt="" />
                                    </Grid>
                                    <Grid item sx={{ margin: '0 !important', padding: '0 !important' }}>
                                        <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }}>{item.filename}</Typography>
                                        <Typography sx={{ color: '#ffffff94' }} variant="subtitle2">{item.date}</Typography>
                                        {(item.width && item.height) &&
                                            <Typography sx={{ color: '#ffffff94' }} variant="subtitle2">{item.width} x {item.height} pixels</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    backgroundColor: '#424242'
                }}>
                <Button onClick={customHandleSelect} disabled={!selected} variant="contained" color="primary">
                    Select
                </Button>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};