import { Box } from '@mui/material';
import { AlertType } from './';

export const Alert = ({
    title,
    richText
}: AlertType) => {
    return (
        <Box component="details" sx={{ pointerEvents: 'none' }} open>
            <Box component="summary" sx={{ backgroundColor: '#ff5f41', fontSize: '24px', padding: '15px 20px' }}>{title}</Box>
            <Box sx={{ padding: '20px' }} dangerouslySetInnerHTML={{ __html: richText }} />
        </Box>
    );
};