import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { normalizeUrl } from '../utilities';
import { types } from './';

const Title = ({ record }: TitleProps) => {
    return <span>Grant {record ? `"${record.title}"` : ''}</span>;
};

export const GrantEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <WidenImageInput source="image" format="externalId" width="300" height="170" validate={required()} />
            <SelectInput source="type" label="Grant Type" choices={types} validate={required()} />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <TextInput source="description" resettable validate={[required(), maxLength(500)]} fullWidth />
            <TextInput source="link" resettable validate={[required(), maxLength(250)]} parse={(x: string) => normalizeUrl(x)} fullWidth />
        </SimpleForm>
    </Edit>
);