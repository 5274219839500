import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { levels } from './';

const Title = ({ record }: TitleProps) => {
    return <span>Sponsor {record ? `"${record.name}"` : ''}</span>;
};

export const SponsorEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <WidenImageInput source="image" format="externalId" width="400" height="300" validate={required()} />
            <SelectInput source="level" choices={levels} validate={required()} />
            <TextInput source="name" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="link" resettable validate={maxLength(250)} fullWidth />
        </SimpleForm>
    </Edit>
);