import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

export const DividerMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('heading')}>
        <ListItemIcon>
            <RemoveIcon />
        </ListItemIcon>
        <ListItemText primary="Divider" />
    </ListItemButton>
);