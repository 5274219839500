import { StaffList } from './StaffList';
import { StaffCreate } from './StaffCreate';
import { StaffEdit } from './StaffEdit';

export default {
    list: StaffList,
    create: StaffCreate,
    edit: StaffEdit,
};

export const offices = [
    { id: 'Film Commission', name: 'Film Commission' },
    { id: 'Finance', name: 'Finance' },
    { id: 'Human Resource Management', name: 'Human Resource Management' },
    { id: 'Information Technology', name: 'Information Technology' },
    { id: 'Office of the Director', name: 'Office of the Director' },
    { id: 'Recreation, Grants and Policy', name: 'Recreation, Grants and Policy' },
    { id: 'Research', name: 'Research' },
    { id: 'Sales & Marketing', name: 'Sales & Marketing' },
    { id: 'State Park Service', name: 'State Park Service' },
];