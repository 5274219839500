import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export const ContentDeleteButton = ({
    onClick,
    className,
}: {
    onClick: any,
    className?: string,
}) => {

    return (
        <IconButton aria-label="Delete" onClick={onClick}>
            <DeleteIcon sx={{
                fill: (theme: any) => theme.palette.secondary.light,
            }} />
        </IconButton>
    );
};