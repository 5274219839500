import {
    ListProps,
    FilterProps,
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    EditButton,
    SelectInput,
    TextInput,
    SearchInput,
    Filter,
} from 'react-admin';

const ListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput label="Active" source="status" choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
        ]} />    
</Filter>
);

export const PageList = (props: ListProps) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="name" />
            <DateField source="publishDate" sortByOrder="DESC" />
            <BooleanField source="status" label="Active" />
            <EditButton />
        </Datagrid>
    </List>
);