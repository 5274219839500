import { Card, CardActions, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';
import { Accordian } from '../accordian';
import { Alert } from '../alert';
import { Cards } from '../cards';
import { Divider } from '../divider';
import { Heading } from '../heading';
import { Hero } from '../hero';
import { Image } from '../image';
import { Jumps } from '../jumps';
import { Resources } from '../resources';
import { RichTextType, RichText } from '../rich-text';
import { Videos, Video } from '../videos';
import {
    ContentType,
    ContentAddButton,
    ContentEditButton,
    ContentDeleteButton,
} from "./";

const StyledContentAddButton = styled(ContentAddButton)(({ theme }) => ({
 color: theme.palette.primary.main,
    }))

export const ContentItem = ({
    content,
    index,
    onChange,
    onAddClick,
    onEditClick,
    onDeleteClick,
    children,
}: {
    content: ContentType,
    index: number,
    onChange: any,
    onAddClick: any,
    onEditClick: any,
    onDeleteClick: any,
    children: any,
}) => {

    const customOnChange = (newValue: any) => {
        const newContent: RichTextType = {
            id: content.id,
            type: 'rich-text',
            richText: newValue,
        }

        onChange(newContent);
    }

    return (
        <Draggable draggableId={String(content.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{
                        position: 'relative',
                        marginBottom: theme => theme.spacing(1),
                        '&:hover': {
                            cursor: 'pointer',
                            '& .MuiCard-root': {
                                boxShadow: (theme: any) => `0 0 0 2px ${theme.palette.secondary.light}`,
                            },
                            '& .MuiCardActions-root': {
                                visibility: 'visible',
                                opacity: 1,
                                transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            },
                            '& .MuiIconButton-root': {
                                visibility: 'visible',
                                opacity: 1,
                                transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            },
                        },
                        '&:active': {
                            '& .MuiCard-root': {
                                boxShadow: (theme: any) => `0 0 0 2px ${theme.palette.secondary.light}`,
                            },
                            '& .MuiCardActions-root': {
                                visibility: 'visible',
                                opacity: 1,
                                transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            },
                        }
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Card
                        sx={{ minHeight: '65px' }}
                        style={{ opacity: snapshot.isDragging ? 0.9 : 1 }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <CardActions disableSpacing sx={{
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: '#424242e2',
                            visibility: 'hidden',
                            opacity: 0,
                            zIndex: 1,
                        }}>
                            <ContentEditButton onClick={onEditClick} />
                            <ContentDeleteButton onClick={onDeleteClick} />
                            <DragIndicatorIcon sx={{ marginLeft: '10px', marginRight: '5px', opacity: .8 }} />
                        </CardActions>
                        <Box sx={{ padding: 0, display: 'flex' }} onClick={onEditClick}>
                            <Box sx={{ width: '100%' }}>
                                {content.type === 'accordian' &&
                                    <Accordian {...content} />
                                }
                                {content.type === 'alert' &&
                                    <Alert {...content} />
                                }
                                {content.type === 'cards' &&
                                    <Cards {...content} />
                                }
                                {content.type === 'divider' &&
                                    <Divider {...content} />
                                }
                                {content.type === 'heading' &&
                                    <Heading {...content} />
                                }
                                {content.type === 'hero' &&
                                    <Hero {...content} />
                                }
                                {content.type === 'image' &&
                                    <Image {...content} />
                                }
                                {content.type === 'jumps' &&
                                    <Jumps {...content} />
                                }
                                {content.type === 'resources' &&
                                    <Resources {...content} />
                                }
                                {content.type === 'rich-text' &&
                                    <RichText {...content} />
                                }
                                {content.type === 'video' &&
                                    <Video {...content} />
                                }
                                {content.type === 'videos' &&
                                    <Videos {...content} />
                                }
                            </Box>
                        </Box>
                    </Card>
                    <Box component={ContentAddButton} onClick={onAddClick} />
                </Box>
            )}
        </Draggable>
    );
};