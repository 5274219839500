import {
    CreateProps,
    Create,
    BooleanInput,
    DateInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { MenuItem } from '@mui/material';
import { Toolbar } from '../components/toolbar'
import { SlugInput, validateSlug } from '../components/slug'
import { ContentInput } from '../components/content';

export const PageCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="name" resettable validate={[required(), maxLength(250)]} fullWidth />
            <SlugInput source="slug" parentSource="name" resettable validate={[required(), maxLength(250), validateSlug]} fullWidth>
                <MenuItem value=""><em>(none)</em></MenuItem>
                <MenuItem value="about/">About</MenuItem>
                <MenuItem value="film-commission/">Film Commission</MenuItem>
                <MenuItem value="film-commission/filming-in-south-carolina/">&nbsp;&nbsp;&nbsp;Filming in South Carolina</MenuItem>
                <MenuItem value="film-commission/filmography/">&nbsp;&nbsp;&nbsp;Filmography</MenuItem>
                <MenuItem value="film-commission/incentives/">&nbsp;&nbsp;&nbsp;Incentives</MenuItem>
                <MenuItem value="film-commission/locations/">&nbsp;&nbsp;&nbsp;Locations</MenuItem>
                <MenuItem value="film-commission/training-and-education/">&nbsp;&nbsp;&nbsp;Training & Education</MenuItem>
                <MenuItem value="governors-conference/">Governor's Conference</MenuItem>
                <MenuItem value="parks/">Parks</MenuItem>
                <MenuItem value="recreation/">Recreation</MenuItem>
                <MenuItem value="tourism/">Tourism</MenuItem>
            </SlugInput>
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <TextInput source="description"  resettable validate={maxLength(500)} multiline fullWidth />
            <TextInput source="keywords" resettable validate={maxLength(250)} fullWidth />
            <DateInput source="publishDate" label="Published" defaultValue={new Date()} validate={required()} />
            <BooleanInput source="status" label="Active" defaultValue={true} />
            <ContentInput source="content" label="" fullWidth />
        </SimpleForm>
    </Create>
);