import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PhonelinkIcon from '@mui/icons-material/Phonelink';

export const ResourcesMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('resources')}>
        <ListItemIcon>
            <PhonelinkIcon />
        </ListItemIcon>
        <ListItemText primary="Resources" />
    </ListItemButton>
);