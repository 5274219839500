export * from './types';
export * from './Resources';
export * from './ResourcesEdit';
export * from './ResourcesMenuItem';
export * from './Resource';

export const ResourcesTemplate = {
    type: 'resources',
};

export const ResourceTemplate = {
    type: 'resource',
};