import { Box } from '@mui/material';
import { ResourceType } from './';

export const Resource = ({
    title,
    richText = '',
    link,
}: ResourceType) => {
    return (
        <Box sx={{
            maxWidth: '550px',
            padding: '20px',
            backgroundColor: '#4d4d4d',
            boxShadow: '5px 5px 20px #00000066',
        }}>
            <h2>{title}</h2>
            <Box dangerouslySetInnerHTML={{ __html: richText }} sx={{ pointerEvents: 'none' }} />
            <Box component="a" href={link} sx={{
                fontSize: 20,
                fontWeight: 'normal',
                color: (theme: any) => theme.palette.secondary.light,
                '&:hover': {
                    color: (theme: any) => theme.palette.secondary.light,
                }
            }}>{link}</Box>
        </Box>
    );
};