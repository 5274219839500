import { Box } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { VideoType, VideoImage } from './';

export const Video = ({
    youtubeId,
    description,
}: VideoType) => {
    return (
        <Box component="figure" sx={{
            paddingTop: '20px',
            paddingBottom: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
        }}>
            {youtubeId &&
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{
                        position: 'absolute',
                        maxWidth: 1140,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <PlayCircleFilledIcon sx={{ width: 75, height: 75 }} />
                    </Box>
                    <VideoImage youtubeId={youtubeId} width={1140} height={640} />
                </Box>
            }
            {description &&
                <Box component="figcaption" sx={{ marginTop: 10, fontStyle: 'italic' }}>{description}</Box>
            }
        </Box>
    );
};