import { Box } from '@mui/material';
import { VideoType, VideoImage } from './';

export const VideoItem = ({
    youtubeId,
    title,
    category,
    description,
}: VideoType) => {
    return (
        <Box sx={{ maxWidth: '350px', backgroundColor: '#4d4d4d', boxShadow: '5px 5px 20px #00000066' }}>
            {youtubeId &&
                <VideoImage youtubeId={youtubeId} width={350} height={250} />
            }
            <Box sx={{ padding: '20px', paddingTop: '20px', textAlign: 'center' }}>
                <Box component="h3" sx={{ color: (theme: any) => theme.palette.secondary.light } }>{category}</Box>
                <Box component="h2" sx={{ fontSize: 16, fontWeight: 'normal' }}>{title}</Box>
                <p>{description}</p>
            </Box>
        </Box>
    );
};