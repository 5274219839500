import { ArrayInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import { makeStyles } from '@mui/material/styles';

export const JumpsEdit = () => (
    <>
        <ArrayInput source="jumps">
            <SimpleFormIterator>
                <TextInput source="link" label="Link" resettable fullWidth />
                <TextInput source="text" label="Text" resettable fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    </>
);