import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';

export const AccordianMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('accordian')}>
        <ListItemIcon>
            <CalendarViewDayIcon />
        </ListItemIcon>
        <ListItemText primary="Accordian" />
    </ListItemButton>
);