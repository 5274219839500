import {
    CreateProps,
    Create,
    BooleanInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar'
import { RichTextInput } from '../components/rich-text';

export const AlertCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <BooleanInput source="active" defaultValue={true} />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Create>
);