import { useState } from 'react';
import {
    InputProps,
    ValidationErrorMessage,
    useInput,
    FieldTitle,
    InputHelperText,
} from 'react-admin';
import { FormHelperText, FormControl, InputLabel, Box } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import {
    WidenImageType,
    WidenImage,
    WidenSearchDialog,
    widenImageUrl
} from './';

export const WidenImageInput = ({
    fullWidth,
    helperText,
    label,
    source,
    resource,
    format = 'object',
    width = '300',
    height = '170',
    ...rest
}:
    // omit 'format" confict
    Omit<InputProps, "format"> & {
        format?: 'object' | 'stringified' | 'url' | 'externalId';
        fullWidth?: boolean;
        width?: number | string;
        height?: number | string;
    }
) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        id,
        isRequired,
        field: { value, onChange },
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
    } = useInput({ source, ...rest });


    const handleSelect = (image: WidenImageType) => {
        switch (format) {
            case 'object':
                onChange(image);
                break;
            case 'stringified':
                onChange(JSON.stringify(image));
                break;
            case 'url':
                onChange(widenImageUrl(image));
                break;
            case 'externalId':
                onChange(image.externalId);
                break;
            default:
                console.log(`ERROR: Unexpected image format!`);
        }
        setOpen(false);
    };

    let externalId = value;
    let filename = undefined;

    if (value.externalId) {
        externalId = value.externalId;
        filename = value.filename;
    } else {
        try {
            const json = JSON.parse(value);
            externalId = json.externalId;
            filename = json.filename;
        } catch { }
    }

    return (
        <>
            <FormControl
                error={!!(isTouched && error)}
                fullWidth={fullWidth}
                className="MuiFilledInput-root"
            >
                <InputLabel shrink htmlFor={id} sx={{
                    position: 'relative',
                    margin: 0,
                    color: error ? 'red' : undefined,

                }}>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </InputLabel>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
                    {externalId
                        ? <WidenImage externalId={externalId} width={width} height={height} />
                        : <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: `${height}px`,
                                width: `${width}px`,
                                padding: '20px',
                                border: '1px solid gray',
                                '&:hover': {
                                    backgroundColor: '#303030',
                                },
                            }}>
                            <ImageSearchIcon sx={{ height: '60px', width: '60px', fill: 'gray' }} />
                        </Box>
                    }
                </Box>
                <FormHelperText
                    error={!!error}
                    sx={{ display: 'none', marginLeft: error ? '12px' : undefined }}>
                    <InputHelperText
                        error={error as unknown as ValidationErrorMessage}
                        helperText={helperText}
                        touched={isTouched}
                    />
                </FormHelperText>
            </FormControl>
            {/*
            {filename &&
                <div className={classes.filename}>{filename}</div>
            }
            */}
            <WidenSearchDialog open={open} handleClose={handleClose} handleSelect={handleSelect} assetType="image" />
        </>
    );
};