import { FilmList } from './FilmList';
import { FilmCreate } from './FilmCreate';
import { FilmEdit } from './FilmEdit';

export default {
    list: FilmList,
    create: FilmCreate,
    edit: FilmEdit,
};

export const types = [
    { "id": "Feature", "name": "Feature" },
    { "id": "Movies of the Week", "name": "Movies of the Week" },
    { "id": "TV Mini-Series", "name": "TV Mini-Series" },
    { "id": "TV Series", "name": "TV Series" }
];

export const genres = [
    { "id": "Action", "name": "Action" },
    { "id": "Adventure", "name": "Adventure" },
    { "id": "Biography", "name": "Biography" },
    { "id": "Comedy", "name": "Comedy" },
    { "id": "Crime", "name": "Crime" },
    { "id": "Documentary", "name": "Documentary" },
    { "id": "Drama", "name": "Drama" },
    { "id": "Faith", "name": "Faith" },
    { "id": "Faith Based", "name": "Faith Based" },
    { "id": "Family", "name": "Family" },
    { "id": "Fantasy", "name": "Fantasy" },
    { "id": "History", "name": "History" },
    { "id": "Horror", "name": "Horror" },
    { "id": "Military", "name": "Military" },
    { "id": "Music", "name": "Music" },
    { "id": "Mystery", "name": "Mystery" },
    { "id": "Reality", "name": "Reality" },
    { "id": "Romance", "name": "Romance" },
    { "id": "Sci-Fi", "name": "Sci-Fi" },
    { "id": "Sports", "name": "Sports" },
    { "id": "Thriller", "name": "Thriller" },
    { "id": "War", "name": "War" }
];