import { ArrayInput, SimpleFormIterator, TextInput, SelectInput, required } from 'react-admin';
import { VideoInput } from '../videos';

const categories = [
    { id: 'Indie Grant Films', name: 'Indie Grant Films' },
    { id: 'Behind the Scenes', name: 'Behind the Scenes' },
    { id: 'Series & Specials', name: 'Series & Specials' },
    { id: 'Music Videos', name: 'Music Videos' },
    { id: 'Other', name: 'Other' },
];

export const VideosEdit = () => (
    <>
        <ArrayInput source="videos">
            <SimpleFormIterator>
                <VideoInput source="youtubeId" label="Video" width={340} height={225} fullWidth validate={required()} />
                <SelectInput source="category" label="Category" choices={categories} fullWidth />
                <TextInput source="title" label="Title" resettable fullWidth validate={required()} />
                <TextInput source="description" label="Description" multiline resettable fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    </>
);