import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
    email,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';

const roles = [
    { id: '', name: 'Default User' },
    { id: 'Administrator', name: 'Administrator' },
];

export const UserCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="firstName" label="First Name" resettable validate={required()} fullWidth />
            <TextInput source="lastName" label="Last Name" resettable validate={required()} fullWidth />
            <TextInput source="email" label="Email Address" resettable validate={[required(), email()]} fullWidth />
            <SelectInput source="role" choices={roles} />
            <BooleanInput source="sendPasswordReset" label="Send Invitation" defaultValue={true} />
        </SimpleForm>
    </Create>
);