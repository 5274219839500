import {
    ToolbarProps,
    SimpleForm,
    Toolbar,
    SaveButton,
    useRecordContext,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Button } from "@mui/material";
import { ContentType } from './';
import { AccordianEdit } from '../accordian';
import { AlertEdit } from '../alert';
import { CardsEdit, CardEdit } from '../cards';
import { DividerEdit } from '../divider';
import { HeadingEdit } from '../heading';
import { HeroEdit } from '../hero';
import { ImageEdit } from '../image';
import { JumpsEdit } from '../jumps';
import { ResourcesEdit } from '../resources';
import { RichTextInput } from '../rich-text';
import { VideosEdit, VideoEdit } from '../videos';

type CustomToolBarProps = ToolbarProps & {
    onCancel: () => void;
};

const CustomToolbar = (props: CustomToolBarProps) => {
    const record = useRecordContext();
    const { isDirty, isValid } = useFormState();
    return (
        <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '500px' }}>
            <SaveButton disabled={!isDirty || !isValid} />
            <Button onClick={props.onCancel}>
                Cancel
            </Button>
        </Toolbar>
    );
}

export function ContentForm({ content, onSave, onCancel }: { content: ContentType, onSave: any, onCancel: any }) {
    return (
        <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />} record={content} onSubmit={onSave}>
            {content.type === 'accordian' &&
                <AccordianEdit />
            }
            {content.type === 'alert' &&
                <AlertEdit />
            }
            {content.type === 'cards' &&
                <CardsEdit />
            }
            {content.type === 'divider' &&
                <DividerEdit />
            }
            {content.type === 'heading' &&
                <HeadingEdit />
            }
            {content.type === 'hero' &&
                <HeroEdit />
            }
            {content.type === 'image' &&
                <ImageEdit />
            }
            {content.type === 'jumps' &&
                <JumpsEdit />
            }
            {content.type === 'resources' &&
                <ResourcesEdit />
            }
            {content.type === 'rich-text' &&
                <RichTextInput source="richText" label="" height="max(250px, calc(100vh - 225px))" autoresize={false} fullWidth />
            }
            {content.type === 'video' &&
                <VideoEdit />
            }
            {content.type === 'videos' &&
                <VideosEdit />
            }
        </SimpleForm>
    );
}