import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route, BrowserRouter } from 'react-router-dom';
import {
    Dashboard as DashboardIcon,
    Description as PagesIcon,
    ImportContacts as ArticlesIcon,
    Assessment as ReportsIcon,
    AccountBalance as GrantsIcon,
    PermContactCalendar as StaffIcon,
    LocalActivity as FilmsIcon,
    RecordVoiceOver as SpeakersIcon,
    Redeem as SponsorsIcon,
    Warning as AlertsIcon,
    SupervisorAccount as UsersIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import { IdentityLogin, IdentityManage } from './identity';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import theme from './theme';
import Layout from './layout';
import Dashboard from './dashboard';
import settings from './settings';
import pages from './pages';
import articles from './articles';
import grants from './grants';
import staff from './staff';
import films from './films';
import speakers from './speakers';
import sponsors from './sponsors';
import reports from './reports';
import alerts from './alerts';
import users from './users';
import advanced from './advanced';

const App = () => (
    <BrowserRouter>
        <Admin
            //requireAuth
            dataProvider={dataProvider}
            authProvider={authProvider}
            theme={theme}
            layout={Layout}
            dashboard={Dashboard}
            title={settings.cmsTitle}
            disableTelemetry
        >
            {permissions => [
                <Resource name="pages" icon={PagesIcon} {...pages} />,
                <Resource name="articles" icon={ArticlesIcon} {...articles} />,
                <Resource name="reports" icon={ReportsIcon} {...reports} />,
                <Resource name="grants" icon={GrantsIcon} {...grants} />,
                <Resource name="staff" icon={StaffIcon} options={{ label: 'Staff' }} {...staff} />,
                <Resource name="films" icon={FilmsIcon} {...films} options={{ label: 'Filmography' }} />,
                <Resource name="films/actors" />,
                <Resource name="films/companies" />,
                <Resource name="films/genres" />,
                <Resource name="films/locations" />,
                <Resource name="films/types" />,
                <Resource name="speakers" icon={SpeakersIcon} options={{ label: 'GovCon Speakers' }} {...speakers} />,
                <Resource name="sponsors" icon={SponsorsIcon} options={{ label: 'GovCon Sponsors' }} {...sponsors} />,
                permissions === 'Administrator' &&
                <Resource name="alerts" icon={AlertsIcon} {...alerts} />,
                permissions === 'Administrator' &&
                <Resource name="users" icon={UsersIcon} {...users} />,
                <Resource name="settings" icon={SettingsIcon} {...advanced} />
            ]}
            <CustomRoutes noLayout>
                <Route exact path={settings.loginPath} element={<IdentityLogin />} />
                <Route exact path={settings.managePath} element={<IdentityManage />} />
            </CustomRoutes>
        </Admin>
    </BrowserRouter>
);

export default App;