import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { RichTextInput } from '../components/rich-text';

export const SpeakerCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <WidenImageInput source="image" format="externalId" width="400" height="300" />
            <TextInput source="name" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="title" resettable validate={maxLength(250)} fullWidth />
            <TextInput source="description" resettable multiline fullWidth />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Create>
);