import {
    EditProps,
    TitleProps,
    Edit,
    BooleanInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar'
import { RichTextInput } from '../components/rich-text';

const Title = ({ record }: TitleProps) => {
    return <span>Alert {record ? `"${record.title}"` : ''}</span>;
};

export const AlertEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic"  {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <BooleanInput source="active" />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Edit>
);