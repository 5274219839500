import { useState } from 'react';
import { InputProps, ValidationErrorMessage, useInput, FieldTitle, InputHelperText } from 'react-admin';
import { FormHelperText, FormControl, InputLabel, Box } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { VideoImage, VideoDialog } from './';

export const VideoInput = ({
    fullWidth,
    helperText,
    label,
    source,
    resource,
    width = '300px',
    height = '170px',
    ...rest
}:
    InputProps & {
        fullWidth?: boolean;
        width?: number | string;
        height?: number | string;
    }
) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        id,
        isRequired,
        field: { value, onChange },
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
    } = useInput({ source, ...rest });

    const handleSubmit = (youtubeId: string) => {
        if (onChange) {
            onChange(youtubeId);
        }
        setOpen(false);
    };

    let youtubeId = value;

    const youtubeUrl = `https://www.youtube.com/watch?v=${youtubeId}`;

    return (
        <>
            <FormControl
                error={!!(isTouched && error)}
                fullWidth={fullWidth}
                className="MuiFilledInput-root"
            >
                <Box component={InputLabel} shrink htmlFor={id} sx={{
                    position: 'relative',
                    marginTop: '5px',
                    marginLeft: '12px',
                    marginBottom: 0,
                    color: error ? 'red' : undefined
                }}>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </Box>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
                    {youtubeId
                        ? <VideoImage youtubeId={youtubeId} width={width} height={height} />
                        : <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: height,
                            width: width,
                            padding: '20px',
                            border: '1px solid gray',
                            '&:hover': {
                                backgroundColor: '#303030',
                            }
                        }}><Box component={YouTubeIcon} sx={{ height: '60px', width: '60px', fill: 'gray' }} /></Box>
                    }
                </Box>
                <FormHelperText
                    error={!!error}
                    sx={{ display: 'none', marginLeft: error ? '12px' : undefined }}>
                    <InputHelperText
                        error={error as unknown as ValidationErrorMessage}
                        helperText={helperText}
                        touched={isTouched}
                    />
                </FormHelperText>
            </FormControl>
            {youtubeId &&
                <a href={youtubeUrl} target="_blank" rel="noopener">{youtubeUrl} <OpenInNewIcon style={{ height: '20px', marginBottom: '2px' }} /></a>
            }
            <VideoDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit} />
        </>
    );
};