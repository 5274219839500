import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PanoramaIcon from '@mui/icons-material/Panorama';

export const HeroMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('hero')}>
        <ListItemIcon>
            <PanoramaIcon />
        </ListItemIcon>
        <ListItemText primary="Hero" />
    </ListItemButton>
);