import {
    ListProps,
    FilterProps,
    List,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    Filter,
} from 'react-admin';

const ListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const UserList = (props: ListProps) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="email" />
            <EditButton />
        </Datagrid>
    </List>
);