import {
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { Chip, Typography, Box } from '@mui/material';
import { normalizeUrl, updateQueryStringParameter } from '../../utilities';
import { debounce } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import { WidenImageType, WidenSearchDialog, widenImageUrl } from '../widen';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import * as TINYMCE from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide-dark/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import contentCss from '!!raw-loader!tinymce/skins/content/dark/content.min.css';
// @ts-ignore
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide-dark/content.min.css';

// @ts-ignore
tinymce = TINYMCE;

export const RichTextEditor = ({
    value: initialValue,
    onChange,
    height,
    autoresize = true,
}: {
    value: string | undefined;
    onChange: any;
    height?: string;
    autoresize?: boolean;
}) => {

    const [value, setValue] = useState<string | undefined>(undefined);
    const [widenOpen, setWidenOpen] = useState(false);
    const editorRef = useRef<any>(undefined);

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const debouncedOnChange = debounce((newValue: string) => {
        onChange(newValue);
    }, 100, { 'maxWait': 1000 });

    const onInit = (evt: any, editor: any) => {
        editor.shortcuts.add("ctrl+l", "Insert/edit a link", "mceLink");
    }

    const setup = (editor: any) => {
        editorRef.current = editor;
        editor.ui.registry.addButton("widen", {
            icon: 'image',
            onAction: () => setWidenOpen(!widenOpen)
        });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const onEditorChange = useCallback(
        (newValue: string, editor: any) => {
            if (value !== newValue) {
                setValue(newValue);
                debouncedOnChange(newValue);
            }
        },
        [onChange]
    );

    const onObjectResized = (event: any, editor: any) => {
        if (event.target.nodeName == 'IMG') {
            var selectedImage = editorRef.current.selection.getNode();
            const width = event.width;
            const height = event.height;
            let src = selectedImage.getAttribute('src');
            src = updateQueryStringParameter(src, 'w', width);
            src = updateQueryStringParameter(src, 'h', height);
            editorRef.current.dom.setAttrib(selectedImage, 'src', src);
        }
    };

    const handleWidenClose = () => {
        setWidenOpen(false);
    };

    const handleWidenSelect = (image: WidenImageType) => {
        const element = `<img src=${widenImageUrl(image)} data-external-id=${image.externalId} alt="" />`
        editorRef.current.insertContent(element);
        setWidenOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '4px' }}>
                <Chip label="Shift + Enter" />
                <Typography variant="body2" display="block" style={{ fontSize: '0.8125rem', opacity: 0.8 }}>
                    for new line instead of new paragraph
                </Typography>
            </Box>
            <Editor
                onInit={onInit}
                onEditorChange={onEditorChange}
                onObjectResized={onObjectResized}
                value={value}
                init={{
                    skin: 'oxide-dark',
                    content_css: 'dark',
                    content_style: [
                        contentCss,
                        contentUiCss,
                        'body { background-color: #303030; } \
                        .mce-content-body [data-mce-selected=inline-boundary] { background-color: black; } \
                        .float-left {float: left; margin: 5px 20px 5px 0; } \
                        .float-right {float: right; margin: 5px 0 5px 20px; } \
                        img {max-width: 100%; height: auto; } \
                        iframe {max-width: 100%; }'
                    ].join('\n'),
                    height: height,
                    autoresize_bottom_margin: 50,
                    menubar: false,
                    branding: false,
                    setup: (editor) => setup(editor),
                    plugins: [
                        'autolink',
                        ...autoresize ? ['autoresize'] : [],
                        'code',
                        'help',
                        'image',
                        'lists',
                        'link',
                        'media',
                        'paste',
                        'searchreplace',
                        'table',
                        'wordcount',
                        'media',
                    ],
                    toolbar: 'code | undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright | bullist numlist outdent indent | ' +
                        'removeformat | link | widen | media | help',
                    toolbar_sticky: true,
                    image_class_list: [
                        { title: '(none)', value: '' },
                        { title: 'Float Left', value: 'float-left' },
                        { title: 'Float Right', value: 'float-right' }
                    ],
                    valid_classes: '',
                    paste_retain_style_properties: '',
                    invalid_elements: 'html,body,head',
                    extended_valid_elements: 'svg[*],g[*],path[*],rect[*],circle[*],polyline[*],line[*],text[*],tspan[*]',
                    urlconverter_callback: (url) => normalizeUrl(url),
                }}
            />
            <WidenSearchDialog open={widenOpen} handleClose={handleWidenClose} handleSelect={handleWidenSelect} assetType="image" />
        </>
    );
};