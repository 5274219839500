import { Box } from '@mui/material';
import { VideoImageType } from './';

export const VideoImage = ({
    youtubeId,
    width,
    height,
}:
    VideoImageType & { fullWidth?: boolean }
) => {
    const src = `https://i.ytimg.com/vi/${youtubeId}/mqdefault.jpg`;

    return (
        <Box component="img" src={src} width={width} height={height} sx={{ maxWidth: '100%', height: 'auto' }} alt="video" />
    );
};