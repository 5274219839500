import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider, SimpleListLoading } from 'react-admin';
import {
    Avatar,
    Box,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';

const Dashboard = () => {
    const [transactions, setTransactions] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();

    const fetchTransactions = useCallback(async () => {
        try {
            const { data } = await dataProvider.getList<any>(
                'transactions',
                {
                    filter: {},
                    sort: { field: 'date', order: 'DESC' },
                    pagination: { page: 1, perPage: 10 },
                }
            );
            if (data) {
                setTransactions(data);
            }
        }
        catch { }

        setLoading(false);
    }, [dataProvider]);

    useEffect(() => {
        fetchTransactions();
    }, []);

    return (
        <>
            <Box component="h3" sx={{ marginTop: '20px !important', marginBottom: '20px !important' }}>Recent Activity</Box>
            {loading &&
                <SimpleListLoading
                sx={{ alignItems: 'flex-start', maxWidth: '600px' }}
                    hasLeftAvatarOrIcon={true}
                    hasRightAvatarOrIcon={false}
                    hasSecondaryText={true}
                    hasTertiaryText={false}
                />
            }
            <List sx={{ alignItems: 'flex-start', maxWidth: '600px' }}>
                {transactions.map((record: any) => (
                    <ListItemButton
                        key={record.id}
                        component={Link}
                        to={`${record.controller.toLowerCase()}/${record.transactionId}`}
                        sx={{
                            '&:hover': {
                                color: 'white',
                            },
                        }}>
                        <ListItemAvatar sx={{ alignSelf: 'center' }}>
                            <Avatar>{`${record.firstName.substring(0, 1).toLowerCase()}${record.lastName.substring(0, 1).toLowerCase()}`}</Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={`${record.firstName} ${record.lastName}`}
                            secondary={record.description}
                            sx={{ maxWidth: '425px' }}
                        />
                        <ListItemText
                            primary={new Date(record.date).toLocaleDateString()}
                            secondary={new Date(record.date).toLocaleTimeString()}
                            sx={{ textAlign: 'right', minWidth: '10px' }}
                        />
                    </ListItemButton>
                ))}
            </List>
        </>
    );
};

export default Dashboard;