import {
    ListProps,
    FilterProps,
    List,
    Datagrid,
    TextField,
    EditButton,
    SearchInput,
    Filter,
} from 'react-admin';

const ListFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

export const SpeakerList = (props: ListProps) => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="title" />
            <EditButton />
        </Datagrid>
    </List>
);