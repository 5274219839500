import { Box } from '@mui/material';
import slugify from 'slugify';
import { WidenImageType } from './';

const DEFAULT_FORMAT = 'web';
const DEFAULT_FILENAME = 'image.jpg';
const DEFAULT_WIDTH = '';
const DEFAULT_HEIGHT = '';
const DEFAULT_CROP = 'yes';
const DEFAULT_KEEP = 'c';
const DEFAULT_COLOR = 'cccccc';
const DEFAULT_QUALITY = 80;
const DEFAULT_TRACKER = 'sgt8lu';

export const WidenImage = ({
    externalId,
    format,
    filename,
    width,
    height,
    crop,
    keep,
    color,
    quality,
    u,
    alt,
    caption,
}: WidenImageType) => {
    const src = widenImageUrl({
        externalId,
        format,
        filename,
        width,
        height,
        crop,
        keep,
        color,
        quality,
        u,
    });
    return (
        <Box component="img" src={src} width={width} height={height} sx={{ maxWidth: '100%', height: 'auto' }} alt={alt} />
    );
};

export const widenImageUrl = ({
    externalId,
    format = DEFAULT_FORMAT,
    filename = DEFAULT_FILENAME,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    crop = DEFAULT_CROP,
    keep = DEFAULT_KEEP,
    color = DEFAULT_COLOR,
    quality = DEFAULT_QUALITY,
    u = DEFAULT_TRACKER,
}: WidenImageType) => {
    return `https://scprt.widen.net/content/${externalId}/${format.toLowerCase()}/${slugify(filename)}?w=${width}&h=${height}&keep=${keep}&crop=${crop}&color=${color}&quality=${quality}&u=${u}`;
}