import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

export const VideoMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('video')} >
        <ListItemIcon>
            <YouTubeIcon />
        </ListItemIcon>
        <ListItemText primary="Video" />
    </ListItemButton>
);