import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { levels } from './';

export const SponsorCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <WidenImageInput source="image" format="externalId" width="400" height="300" validate={required()} />
            <SelectInput source="level" choices={levels} validate={required()} />
            <TextInput source="name" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="link" resettable validate={maxLength(250)} fullWidth />
        </SimpleForm>
    </Create>
);