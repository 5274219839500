import { AuthProvider } from 'react-admin';
import settings from './settings';

const authProvider: AuthProvider = {
    login: (params: any) => Promise.resolve(),
    logout: () => Promise.resolve(),
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem(settings.identityStoreName);
            return Promise.reject({ redirectTo: settings.loginPath });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: async (params: any) => {
        // remove legacy oidc storage
        localStorage.removeItem(settings.legacyIdentityStoreName);

        await fetch(settings.identityPath)
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.setItem(settings.identityStoreName, JSON.stringify(data));
                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.reject({ redirectTo: settings.loginPath });
            });
    },
    getIdentity: async () => {
        const identityStore = sessionStorage.getItem(settings.identityStoreName);
        if (!identityStore) {
            return Promise.reject();
        }
        try {
            const { id = '', firstName: fullName = '', avatar = '' } = JSON.parse(identityStore);
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject();
        }
    },
    getPermissions: async (params: any) => {
        const identityStore = sessionStorage.getItem(settings.identityStoreName);
        if (!identityStore) {
            return Promise.reject({ redirectTo: settings.loginPath });
        }
        try {
            const { role } = JSON.parse(identityStore);
            return Promise.resolve(role);
        }
        catch (error) {
            return Promise.reject({ redirectTo: settings.loginPath });
        }
    }
};

export default authProvider;