import {
    ToolbarProps,
    RaRecord,
    useResourceContext,
    useRecordContext,
    useNotify,
    useRedirect,
    useRefresh,
    Toolbar,
    SaveButton,
    ListButton,
    DeleteButton,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Button } from '@mui/material';

const CustomToolbar = (props: ToolbarProps) => {
    const resource = useResourceContext(props);
    const record = useRecordContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSaveSuccess = (data: RaRecord) => {
        notify('Saved successfully', { type: 'success' });
        if (window.location.pathname.endsWith('/create')) {
            redirect('edit', `/${resource}`, data.id);
        }
        refresh();
    };

    const onSaveFailure = () => {
        notify('Error on save', { type: 'error' });
    };

    const onDeleteSuccess = () => {
        notify('Deleted successfully', { type: 'success' });
        redirect(`/${resource}`);
        refresh();
    };

    const onDeleteFailure = () => {
        notify('Error on delete', { type: 'error' });
    };

    const { isDirty, isValid } = useFormState();

    return (
        <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton type="button" mutationOptions={{ onSuccess: onSaveSuccess, onError: onSaveFailure }} disabled={!isDirty} />
            <ListButton label="Back to List" />
            {record?.id
                ? <DeleteButton mutationOptions={{ onSuccess: onDeleteSuccess, onError: onDeleteFailure }} />
                : <Button href={`/${resource}`}>Cancel</Button>
            }
        </Toolbar>
    );
};

export { CustomToolbar as Toolbar };