import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { RichTextInput } from '../components/rich-text';

const Title = ({ record }: TitleProps) => {
    return <span>Speaker {record ? `"${record.name}"` : ''}</span>;
};

export const SpeakerEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <WidenImageInput source="image" format="externalId" width="400" height="300" />
            <TextInput source="name" resettable validate={[maxLength(250), required()]} fullWidth />
            <TextInput source="title" resettable validate={maxLength(250)} fullWidth />
            <TextInput source="description" resettable multiline fullWidth />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Edit>
);