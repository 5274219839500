import { ReportList } from './ReportList';
import { ReportCreate } from './ReportCreate';
import { ReportEdit } from './ReportEdit';

export default {
    list: ReportList,
    create: ReportCreate,
    edit: ReportEdit,
};

export const types = [
    { id: 'Monthly', name: 'Monthly' },
    { id: 'Quarterly', name: 'Quarterly' },
    { id: 'Yearly or Infrequent ', name: 'Yearly or Infrequent' },
];

const endYear = new Date().getFullYear() + 1; // include next year

const startYear = 2009;
export const years = [...Array((endYear - startYear) + 1)].map((item, index) => {
    return {
        id: `${(endYear - index)}`,
        name: `${(endYear - index)}`,
    };
});

const startFiscalYear = 2002;
export const fiscalYears = [...Array((endYear - startFiscalYear) + 1)].map((item, index) => {
    return {
        id: `FY${((endYear - index) - 1).toString().substr(-2)}-${(endYear - index).toString().substr(-2)}`,
        name: `FY${((endYear - index) - 1).toString().substr(-2)}-${(endYear - index).toString().substr(-2)}`,
    };
});

export const quarters = [
    { id: 'Q1', name: 'Q1' },
    { id: 'Q2', name: 'Q2' },
    { id: 'Q3', name: 'Q3' },
    { id: 'Q4', name: 'Q4' },
];

export const months = [
    { id: '1', name: '1 - January' },
    { id: '2', name: '2 - February' },
    { id: '3', name: '3 - March' },
    { id: '4', name: '4 - April' },
    { id: '5', name: '5 - May' },
    { id: '6', name: '6 - June' },
    { id: '7', name: '7 - July' },
    { id: '8', name: '8 - August' },
    { id: '9', name: '9 - September' },
    { id: '10', name: '10 - October' },
    { id: '11', name: '11 - November' },
    { id: '12', name: '12 - December' },
];