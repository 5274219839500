import { useState } from 'react';
import {
    EditProps,
    TitleProps,
    useCreate,
    useCreateSuggestionContext,
    Edit,
    BooleanInput,
    DateInput,
    SimpleForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    ReferenceInput,
    ReferenceArrayInput,
    required,
    maxLength,
} from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import { Toolbar } from '../components/toolbar'
import { LinkField } from '../components/link'
import { WidenImageInput } from '../components/widen';
import { RichTextInput } from '../components/rich-text';
import { SlugInput, validateSlug } from '../components/slug'
import { types, genres } from './';

const Title = ({ record }: TitleProps) => {
    return <span>Film {record ? `"${record.title}"` : ''}</span>;
};

export const FilmEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic"  {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <LinkField source="slug" pathPrefix="film-commission/filmography/films/" />
            <TextInput source="id" readOnly fullWidth />
            <WidenImageInput source="image" label="Poster Image" format="stringified" width="175" height="250" />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <SlugInput source="slug" parentSource="title" disabledSource="active" resettable validate={[required(), maxLength(250), validateSlug]} fullWidth />
            <TextInput source="link" label="IMDb Link" resettable validate={maxLength(250)} fullWidth />
            <DateInput source="startDate" label="Start Date" validate={required()} />
            <DateInput source="endDate" label="End Date" validate={required()} />
            <SelectInput
                source="type"
                choices={types}
                validate={required()}
            />
            <ReferenceInput label="Company" source="company" reference="films/companies">
                <AutocompleteInput
                    optionText="name"
                    validate={required()}
                    create={<CreateCompany />}
                />
            </ReferenceInput>
            <AutocompleteArrayInput
                source="genres"
                choices={genres}
                validate={required()}
            />
            <ReferenceArrayInput label="Actors" source="actors" reference="films/actors">
                <AutocompleteArrayInput
                    optionText="name"
                    validate={required()}
                    create={<CreateActor />}
                />
            </ReferenceArrayInput>
            <ReferenceArrayInput label="Locations" source="locations" reference="films/locations">
                <AutocompleteArrayInput
                    optionText="name"
                    validate={required()}
                    create={<CreateLocation />}
                />
            </ReferenceArrayInput>
            <BooleanInput source="active" />
            <RichTextInput source="description" label="" fullWidth />
        </SimpleForm>
    </Edit>
);

const CreateCompany = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'films/companies',
            { data: { company: value } },
            { onSuccess: data => { setValue(''); onCreate(data); }}
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="New Company"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const CreateActor = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'films/actors',
            { data: { actor: value } },
            { onSuccess: data => { setValue(''); onCreate(data); } }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="New Actor"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const CreateLocation = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'films/locations',
            { data: { location: value } },
            { onSuccess: data => { setValue(''); onCreate(data); } }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="New Location"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};