import { useState } from 'react';
import {
    ListProps,
    List,
    Datagrid,
    TextField,
    EditButton,
    useNotify,
    usePermissions,
    fetchUtils,
} from 'react-admin';
import { Box, Button, CircularProgress } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { styled } from '@mui/material/styles';
import settings from '../settings';

const StyledCircularProgress = styled(CircularProgress)({
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
});

const ListActions = () => (
    <></>
);

export const SettingList = (props: ListProps) => {
    const notify = useNotify();
    const { permissions } = usePermissions();

    const [refreshingCache, setRefreshingCache] = useState(false);

    const handleCache = async () => {
        setRefreshingCache(true);

        await fetch(settings.cachePath)
            .then((response) => response.json())
            .then((data) => {
                setRefreshingCache(false);
                notify('Cache refreshed', { type: 'success' });
            })
            .catch((error) => {
                setRefreshingCache(false);
                notify('Error refreshing the cache', { type: 'error' });
            });
    };

    return (
        <>
            <Box sx={{ marginTop: '20px !important', marginBottom: '20px !important', maxWidth: 700 }}>
                <p>The website caches content for approximately one hour to improve performance.
                    Refreshing the cache allows new/updated content to replace existing content before the cache expires normally.
                    Refreshing the cache will temporarily disrupt the availability of the website.
                </p>
                <Box
                    sx={{
                        position: 'relative',
                        width: 'fit-content',
                        marginTop: '20px !important',
                        marginBottom: '0 !important',
                        maxWidth: 700,
                    }}>
                    <Button onClick={handleCache} disabled={refreshingCache} variant="contained" color="secondary" sx={{ backgroundColor: (theme: any) => theme.palette.secondary.light }} size="large" endIcon={<CachedIcon />}>Refresh the Cache</Button>
                    {refreshingCache &&
                        <StyledCircularProgress size={24} />
                    }
                </Box>
            </Box>
            {
                permissions === "Administrator" &&
                <List
                    {...props}
                    actions={<ListActions />}>
                    <Datagrid rowClick="edit" bulkActionButtons={false}>
                        <TextField source="name" />
                        <EditButton />
                    </Datagrid>
                </List>
            }
        </>)
};
