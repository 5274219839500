import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

export const JumpsMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('jumps')}>
        <ListItemIcon>
            <MenuOpenIcon />
        </ListItemIcon>
        <ListItemText primary="Jump Menu" />
    </ListItemButton>
);