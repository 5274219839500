export * from './types';
export * from './Cards';
export * from './CardsEdit';
export * from './CardsMenuItem';
export * from './CardMenuItem';
export * from './Card';
export * from './CardEdit';

export const CardsTemplate = {
    type: 'cards',
};

export const CardTemplate = {
    type: 'card',
};