import { createRoot } from 'react-dom/client';
import App from './App';

window.addEventListener("DOMContentLoaded", () => {
    checkForUpdates();
});

document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
        checkForUpdates(true);
    }
});

async function checkForUpdates(reload = false) {
    try {
        const response = await fetch("/api/updates");
        const jsonData = await response.json();
        const currentVersion = jsonData.currentVersion;
        const sessionVersion = sessionStorage.getItem("version");
        if (sessionVersion !== currentVersion) {
            sessionStorage.setItem("version", currentVersion);
            if (reload) {
                console.log('Updating to new version...');
                window.location.reload();
            }
        }
    } catch {
        console.log('Unable to check for updates');
    }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);