import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from '@mui/material';

export const ContentAddButton = ({
    onClick,
    visible = false,
}: {
    onClick: any,
    visible?: boolean,
}) => {
    return (
        <IconButton onClick={onClick} aria-label="Add" sx={{
            position: 'absolute',
            bottom: '-26px',
            zIndex: 1,
            visibility: 'hidden',
            opacity: 0,
            ...(visible && {
                position: 'relative',
                bottom: 'unset',
                visibility: 'visible',
                opacity: 1,
            }),
            width: '100%',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: (theme: any) => `${theme.palette.secondary.light}40`  // appending alpha value
            }
        }}>
            <AddCircleIcon sx={{
                fill: (theme: any) => theme.palette.secondary.light,
                width: '50px',
                height: '50px',
                backgroundColor: 'white',
                borderRadius: '50%',
            }} />
        </IconButton>
    );
};