import { ArrayInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import { RichTextInput } from '../rich-text';
import { normalizeUrl } from '../../utilities';

export const ResourcesEdit = () => (
    <>
        <ArrayInput source="resources">
            <SimpleFormIterator>
                <TextInput source="title" label="Title" resettable fullWidth />
                <TextInput source="link" label="Link" resettable fullWidth parse={(x: string) => normalizeUrl(x)} />
                <RichTextInput source="richText" label="" autoresize={false} fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    </>
);