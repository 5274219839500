import { useState, useEffect } from 'react';
import { TextInputProps, TextInput, FormDataConsumer, useInput } from 'react-admin';
import { FormControl, Select, InputLabel, Box } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { slugify } from './';

export const SlugInput = ({
    source,
    parentSource = '',
    disabledSource = '',
    children,
    ...rest
}:
    TextInputProps & {
        parentSource?: string;
        disabledSource?: string;
    }) => {
  
    const { field: { onChange } } = useInput({ source, ...rest });
    const parentValue = useWatch({ name: parentSource });
    const [prefix, setPrefix] = useState('');

    useEffect(() => {
        if (!disabledSource) {
            onChange(`${prefix}${slugify(parentValue)}`);
        }
    }, [prefix, parentValue]);

    return (
        <FormDataConsumer subscription={{ values: true }} {...rest}>
            {({ formData, ...rest }) => (
                <Box sx={{ display: 'flex', width: '100%' }}>
                    {children &&
                        <Box component={FormControl} variant="filled" margin="dense" sx={{ minWidth: 170, marginRight: '15px' }}>
                            <InputLabel id="select-label">Section</InputLabel>
                            <Select labelId="select-label" variant="filled" defaultValue="" onChange={event => setPrefix(event.target.value)}>
                                {children}
                            </Select>
                        </Box>
                    }
                    <TextInput source={source} readOnly={(disabledSource) ? formData[disabledSource] : undefined} {...rest} />
                </Box>
            )}
        </FormDataConsumer>
    );
};