import { List } from '@mui/material/';
import { AccordianType, AccordianTemplate, AccordianMenuItem } from '../accordian';
import { AlertType, AlertTemplate, AlertMenuItem } from '../alert';
import { CardsType, CardsTemplate, CardsMenuItem } from '../cards';
import { DividerType, DividerTemplate, DividerMenuItem } from '../divider';
import { HeadingType, HeadingTemplate, HeadingMenuItem } from '../heading';
import { HeroType, HeroTemplate, HeroMenuItem } from '../hero';
import { ImageType, ImageTemplate, ImageMenuItem } from '../image';
import { JumpsType, JumpsTemplate, JumpsMenuItem } from '../jumps';
import { ResourcesType, ResourcesTemplate, ResourcesMenuItem } from '../resources';
import { RichTextType, RichTextTemplate, RichTextMenuItem } from '../rich-text';
import { VideosType, VideosTemplate, VideosMenuItem, VideoType, VideoTemplate, VideoMenuItem } from '../videos';

export const ContentMenuList = ({ handleClick }: { handleClick: any }) => (
    <List>
        <AccordianMenuItem handleClick={() => handleClick(AccordianTemplate as AccordianType)} />
        <AlertMenuItem handleClick={() => handleClick(AlertTemplate as AlertType)} />
        <CardsMenuItem handleClick={() => handleClick(CardsTemplate as CardsType)} />
        <DividerMenuItem handleClick={() => handleClick(DividerTemplate as DividerType)} />
        <HeadingMenuItem handleClick={() => handleClick(HeadingTemplate as HeadingType)} />
        <HeroMenuItem handleClick={() => handleClick(HeroTemplate as HeroType)} />
        <ImageMenuItem handleClick={() => handleClick(ImageTemplate as ImageType)} />
        <JumpsMenuItem handleClick={() => handleClick(JumpsTemplate as JumpsType)} />
        <ResourcesMenuItem handleClick={() => handleClick(ResourcesTemplate as ResourcesType)} />
        <RichTextMenuItem handleClick={() => handleClick(RichTextTemplate as RichTextType)} />
        <VideoMenuItem handleClick={() => handleClick(VideoTemplate as VideoType)} />
        <VideosMenuItem handleClick={() => handleClick(VideosTemplate as VideosType)} />
    </List>
);