import {
    EditProps,
    TitleProps,
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';

const Title = ({ record }: TitleProps) => {
    return <span>Setting {record ? `"${record.name}"` : ''}</span>;
};

export const SettingEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <TextInput source="id" readOnly fullWidth />
            <TextInput source="name" resettable validate={required()} fullWidth />
            <TextInput source="value" resettable validate={required()} fullWidth />
        </SimpleForm>
    </Edit>
);