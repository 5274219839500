import { Box } from '@mui/material';
import { AccordianItemType } from './';

export const AccordianItem = ({
    title,
    richText,
}: AccordianItemType) => {
    return (
        <Box component="details" sx={{ pointerEvents: 'none', border: '1px solid #aaa', marginTop: -1 }} open>
            <Box component="summary" sx={{ fontSize: '24px', padding: '15px 20px', borderBottom: '1px solid #aaa' }}>{title}</Box>
            <Box sx={{ padding: '20px' }} dangerouslySetInnerHTML={{ __html: richText }} />
        </Box>
    );
};