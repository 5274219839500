import slugify from 'slugify';
import { WidenPdfType, WidenImage } from './';

const DEFAULT_FORMAT = 'PDF';
const DEFAULT_FILENAME = 'document.pdf';
const DEFAULT_WIDTH = 300;
const DEFAULT_HEIGHT = '';
const DEFAULT_TRACKER = 'sgt8lu';

export const WidenPdf = ({
    externalId,
    filename = DEFAULT_FILENAME,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
}: WidenPdfType) => {
    return (
        <WidenImage externalId={externalId} width={width} height={height} />
    );
};

export const widenPdfUrl = ({
    externalId,
    format = DEFAULT_FORMAT,
    filename = DEFAULT_FILENAME,
    u = DEFAULT_TRACKER,
}: WidenPdfType) => {
    return `https://scprt.widen.net/content/${externalId}/${format.toLowerCase()}/${slugify(filename)}?u=${u}`;
}