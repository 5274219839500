import { Box } from '@mui/material';
import { ImageType } from './';
import { WidenImage } from '../widen';

export const Image = ({
    image,
    alt,
    caption,
}: ImageType) => {

    return (
        <Box component="figure" sx={{
            paddingTop: '20px',
            paddingBottom: '10px',
            paddingLeft: '20px',
            paddingRight: '20px'
        }}>
            <WidenImage externalId={image.externalId} filename={image.filename} width={1140} alt={alt} />
            {caption &&
                <Box component="figcaption" sx={{ marginTop: '10px', fontStyle: 'italic' }}>{caption}</Box>
            }
        </Box>
    )
};