import {
    InputProps,
    ValidationErrorMessage,
    useInput,
    InputHelperText,
} from 'react-admin';
import { FormHelperText, FormControl } from '@mui/material';
import { RichTextEditor } from './RichTextEditor';

export const RichTextInput = ({
    fullWidth = true,
    helperText,
    label = '',
    source,
    resource,
    height,
    autoresize = true,
    ...rest
}:
    InputProps & {
        autoresize?: boolean;
        fullWidth?: boolean;
        height?: string;
    }
) => {

    const {
        id,
        isRequired,
        field: { value, onChange },
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
    } = useInput({ source, ...rest });

    return (
        <FormControl
            error={!!(isTouched && error)}
            fullWidth={fullWidth}
            className="ra-rich-text-input"
        >
            <RichTextEditor
                value={value}
                onChange={onChange}
                height={height}
                autoresize={autoresize}
            />
            <FormHelperText
                error={!!error}
                className={!!error ? 'ra-rich-text-input-error' : ''}
            >
                <InputHelperText
                    error={error as unknown as ValidationErrorMessage}
                    helperText={helperText}
                    touched={isTouched}
                />
            </FormHelperText>
        </FormControl>
    );
};