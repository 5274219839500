import { Box } from '@mui/material';
import { ResourcesType, Resource } from './';

export const Resources = ({
    resources
}: ResourcesType) => {
    return (
        <Box sx={{
            display: 'grid',
            gridGap: '20px',
            padding: '20px',
            maxWidth: '1200px',
            '@media(min-width: 1300px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
        }}>
            {resources && resources.map((resource, index) => (
                <Resource {...resource} key={resource.id} />
            ))}
        </Box>
    );
};