import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CallToActionIcon from '@mui/icons-material/CallToAction';

export const CardsMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('cards')}>
        <ListItemIcon>
            <CallToActionIcon />
        </ListItemIcon>
        <ListItemText primary="Cards" />
    </ListItemButton>
);