import { Layout, AppBar, UserMenu, MenuItemLink } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import settings from '../settings';

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <MenuItemLink
            to={settings.managePath}
            primaryText="Manage Account"
            leftIcon={<PersonIcon />}
        />
    </UserMenu>
);

const CustomAppBar = (props: any) => <AppBar {...props} userMenu={<CustomUserMenu />} />;

export const CustomLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />;