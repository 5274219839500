import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';

export const RichTextMenuItem = ({ handleClick }: { handleClick: any }) => (
    <ListItemButton onClick={() => handleClick('rich-text')}>
        <ListItemIcon>
            <SubjectIcon />
        </ListItemIcon>
        <ListItemText primary="Rich Text" />
    </ListItemButton>
);