import {
    ListProps,
    List,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
} from 'react-admin';

export const AlertList = (props: ListProps) => (
    <List {...props}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="title" />
            <BooleanField source="active" />
            <EditButton />
        </Datagrid>
    </List>
);