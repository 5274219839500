import {
    useRef,
    useState,
    useContext,
    createContext,
    ReactNode,
    MouseEvent,
    KeyboardEvent,
} from 'react';
import { Drawer, Box } from '@mui/material';
import { ContentMenuList } from './';
import { ContentType } from './types';

type ProviderContext = readonly [(handleAdd: any, contentId?: string) => void, () => void];

const EMPTY_FUNC = () => { };
const DrawerContext = createContext<ProviderContext>([
    EMPTY_FUNC,
    EMPTY_FUNC
]);

export const useDrawer = () => useContext(DrawerContext);

export function DrawerProvider({ children }: { children: ReactNode }) {
    const [open, setOpen] = useState(false);
    const onMenuClickRef = useRef<any>(undefined);
    const activeIdRef = useRef<string | undefined>(undefined);

    const openDrawer = (onMenuClick: any, activeId?: string) => {
        onMenuClickRef.current = onMenuClick;
        activeIdRef.current = activeId;
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    const handleClose = (event: KeyboardEvent | MouseEvent) => {
        if (event.type === 'keydown' && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')) {
            return;
        }
        closeDrawer();
        onMenuClickRef.current = undefined;
        activeIdRef.current = undefined;
    };

    const handleClick = (content: ContentType) => {
        onMenuClickRef.current(content, activeIdRef.current);
    };

    const contextValue = useRef([openDrawer, closeDrawer] as const);

    return (
        <DrawerContext.Provider value={contextValue.current}>
            {children}
            <Drawer anchor="right" open={open} onClose={handleClose}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={handleClose}
                    onKeyDown={handleClose}
                >
                    <ContentMenuList handleClick={handleClick} />
                </Box>
            </Drawer>
        </DrawerContext.Provider>
    );
}