import { IconButton } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";

export const ContentEditButton = ({
    onClick,
}: {
    onClick: any,
}) => {
    return (
        <IconButton onClick={onClick} aria-label="Edit">
            <EditIcon sx={{
                fill: (theme: any) => theme.palette.secondary.light,
            }} />
        </IconButton>
    );
};