import { regex } from 'react-admin';
import slugify from 'slugify';
export * from './SlugInput';

const customSlugify = (text: string) => {
    if (!text) return '';

    return slugify(text, {
        lower: true,      // convert to lower case, defaults to `false`
        strict: true,     // strip special characters except replacement, defaults to `false`
        trim: true,       // trim leading and trailing replacement chars, defaults to `true`
    });
}
export { customSlugify as slugify };

export const validateSlug = regex(/^[a-z0-9\/]+(?:-[a-z0-9\/]+)*$/, 'Only lowercase letters, numbers, "-", "/" allowed. Example: "top-10-suggestions"');