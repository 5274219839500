export * from './types';
export * from './Video';
export * from './VideoItem';
export * from './VideoEdit';
export * from './VideoImage';
export * from './VideoInput';
export * from './VideoDialog';
export * from './VideoMenuItem';
export * from './Videos';
export * from './VideosEdit';
export * from './VideosMenuItem';

export const VideosTemplate = {
    type: 'videos',
};

export const VideoTemplate = {
    type: 'video',
};