import { GrantList } from './GrantList';
import { GrantCreate } from './GrantCreate';
import { GrantEdit } from './GrantEdit';

export default {
    list: GrantList,
    create: GrantCreate,
    edit: GrantEdit,
};

export const types = [
    { id: 'Recreation', name: 'Recreation' },
    { id: 'Tourism', name: 'Tourism' },
    { id: 'Film', name: 'Film' },
];