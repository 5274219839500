import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    DateInput,
    required,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenPdfInput } from '../components/widen';
import {
    types,
    years,
    fiscalYears,
    quarters,
    months,
} from './';

export const ReportCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <WidenPdfInput source="pdf" label="PDF" format="stringified" width="300" height="170" validate={required()} />
            <TextInput source="title" resettable validate={required()} fullWidth />
            <SelectInput source="type" label="Report Type" choices={types} validate={required()} />
            <SelectInput source="year" label="Year" choices={years} />
            <SelectInput source="fiscalYear" label="Fiscal Year" choices={fiscalYears} />
            <SelectInput source="quarter" label="Quarter" choices={quarters} />
            <SelectInput source="month" label="Month" choices={months} />
            <DateInput source="publishDate" label="Published" defaultValue={new Date()} validate={required()} />
        </SimpleForm>
    </Create>
);