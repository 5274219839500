import {
    CreateProps,
    Create,
    BooleanInput,
    DateInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar'
import { SlugInput, validateSlug } from '../components/slug';
import { WidenImageInput } from '../components/widen';
import { RichTextInput } from '../components/rich-text';

export const ArticleCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <WidenImageInput source="image" format="externalId" width="300" height="170" />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <SlugInput source="slug" parentSource="title" resettable validate={[required(), maxLength(250), validateSlug]} fullWidth />
            <TextInput source="description" resettable validate={[required(), maxLength(500)]} fullWidth />
            <DateInput source="publishDate" label="Published" defaultValue={new Date()} validate={required()} />
            <BooleanInput source="status" label="Active" defaultValue={true} />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Create>
);