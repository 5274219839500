import { InputProps, useInput, FieldTitle, ValidationErrorMessage } from 'react-admin';
import { InputHelperText } from 'ra-ui-materialui';
import {
    FormHelperText,
    FormControl,
    InputLabel,
    PropTypes as MuiPropTypes,
} from '@mui/material';
import {
    ContentType,
    ContentList,
    DrawerProvider,
    DialogProvider,
} from './';

const EMPTY_FUNC = () => { };

export const ContentInput = ({
    fullWidth = true,
    helperText,
    label,
    source,
    resource,
    ...rest
}:
    InputProps & {
        fullWidth?: boolean;
    }
) => {

    const {
        id,
        isRequired,
        field: { value, onChange },
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
    } = useInput({ source, ...rest });

    return (
        <DrawerProvider>
            <DialogProvider>
                <FormControl
                    error={!!(isTouched && error)}
                    fullWidth={fullWidth}
                    className="content-input"
                >
                    <InputLabel shrink htmlFor={id}>
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    </InputLabel>
                    <ContentList
                        data={value}
                        onChange={onChange ?? EMPTY_FUNC}
                        touched={isTouched}
                    />
                    <FormHelperText
                        error={!!error}
                        className={!!error ? 'content-input-error' : ''}
                    >
                        <InputHelperText
                            error={error as unknown as ValidationErrorMessage}
                            helperText={helperText}
                            touched={isTouched}
                        />
                    </FormHelperText>
                </FormControl>
            </DialogProvider>
        </DrawerProvider>
    );
};