import { defaultTheme } from 'react-admin';
import blue from '@mui/material/colors/blue';

const theme = {
    ...defaultTheme,
    //spacing: 1,
    palette: {
        mode: 'dark',
        primary: blue,
        secondary: blue,
    },
    components: {
        ...defaultTheme.components,
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    backgroundColor: '#2196f3',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#303030',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                        marginBottom: 0,
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: 'white',
                    },
                },
            },
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    '& .RaLayout-content, .RaLayout-contentWithSidebar': {
                        backgroundColor: '#303030',
                    },
                },
            },
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    '& .RaSidebar-fixed': {
                        backgroundColor: '#303030',
                    },
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 'bold',
                        backgroundColor: '#303030',
                    },
                },
            },
        },
        RaPlaceholder: {
            styleOverrides: {
                root: {
                    animation: 'pulse 1.5s ease-in-out infinite',
                },
            },
        },
    }
};

export default theme;