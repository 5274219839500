import {
    useRef,
    useState,
    useContext,
    createContext,
    ReactNode,
} from 'react';
import { Dialog, DialogContent } from "@mui/material";
import { ContentType, ContentForm } from './';

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const EMPTY_FUNC = () => { };
const DialogContext = createContext<ProviderContext>([
    EMPTY_FUNC,
    EMPTY_FUNC
]);

export const useDialog = () => useContext(DialogContext);

type DialogParams = {
    content: ContentType;
    open: boolean;
    onSave?: any;
    onClose?: Function;
    options?: any;
};
type DialogOption = Omit<DialogParams, "open">;
type DialogContainerProps = DialogParams & {
    onSave: () => void;
    onClose: () => void;
};

function DialogContainer(props: DialogContainerProps) {
    const { content, open, onSave, onClose, options } = props;

    return (
        <Dialog open={open} onClose={onClose} {...options}>
            <DialogContent>
                <ContentForm content={content} onSave={onSave} onCancel={onClose} />
            </DialogContent>
        </Dialog>
    );
}

export function DialogProvider({ children }: { children: ReactNode }) {
    const [dialogs, setDialogs] = useState<DialogParams[]>([]);

    const createDialog = (option: DialogOption) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };

    const closeDialog = () => {
        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();
            if (!latestDialog) return dialogs;
            if (latestDialog.onClose) latestDialog.onClose();
            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = useRef([createDialog, closeDialog] as const);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialogs.map((dialog, i) => {
                const { onSave, onClose, ...dialogParams } = dialog;
                return (
                    <DialogContainer
                        key={i}
                        onSave={onSave}
                        onClose={closeDialog}
                        {...dialogParams}
                    />
                );
            })}
        </DialogContext.Provider>
    );
}