import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar';
import { WidenImageInput } from '../components/widen';
import { normalizeUrl } from '../utilities';
import { types } from './';

export const GrantCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <WidenImageInput source="image" format="externalId" width="300" height="170" validate={required()} />
            <SelectInput source="type" label="Grant Type" choices={types} validate={required()} />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <TextInput source="description" resettable validate={[required(), maxLength(500)]} fullWidth />
            <TextInput source="link" resettable validate={[required(), maxLength(250)]} parse={(x: string) => normalizeUrl(x)} fullWidth />
        </SimpleForm>
    </Create>
);