import { HeroType } from './';
import { widenImageUrl } from '../widen';
import { Box } from '@mui/material';

export const Hero = ({
    title,
    description,
    image,
}: HeroType) => {
    image.width = 1200;
    image.height = 465;
    const imageUrl = widenImageUrl(image);
    return (
        <Box sx={{
            position: 'relative',
            height: '465px',
            padding: '20px 10%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(0 0 0 / 25%)',
            },
        }}>
            <Box component="h1" sx={{ zIndex: 1 }}>{title}</Box>
            <Box component="p" sx={{ zIndex: 1 }}>{description}</Box>
        </Box>
    );
}
