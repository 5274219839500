import {
    EditProps,
    TitleProps,
    Edit,
    BooleanInput,
    DateInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar'
import { LinkField } from '../components/link'
import { SlugInput, validateSlug } from '../components/slug'
import { ContentInput } from '../components/content';

const Title = ({ record }: TitleProps) => {
    return <span>Page {record ? `"${record.name}"` : ''}</span>;
};

export const PageEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic" {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <LinkField source="slug" />
            <TextInput source="id" readOnly fullWidth />
            <TextInput source="name" resettable validate={[required(), maxLength(250)]} fullWidth />
            <SlugInput source="slug" parentSource="name" disabledSource="status" resettable validate={[required(), maxLength(250), validateSlug]} fullWidth />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <TextInput source="description" resettable validate={maxLength(500)}  multiline fullWidth />
            <TextInput source="keywords" resettable validate={maxLength(250)}  fullWidth />
            <DateInput source="publishDate" label="Published" required />
            <BooleanInput source="status" label="Active" />
            <ContentInput source="content" label="" fullWidth />
        </SimpleForm>
    </Edit>
);