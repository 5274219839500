import {
    EditProps,
    TitleProps,
    Edit,
    BooleanInput,
    DateInput,
    SimpleForm,
    TextInput,
    required,
    maxLength,
} from 'react-admin';
import { Toolbar } from '../components/toolbar'
import { LinkField } from '../components/link'
import { WidenImageInput } from '../components/widen';
import { RichTextInput } from '../components/rich-text';
import { SlugInput, validateSlug } from '../components/slug'

const Title = ({ record }: TitleProps) => {
    return <span>Article {record ? `"${record.title}"` : ''}</span>;
};

export const ArticleEdit = (props: EditProps) => (
    <Edit title={<Title />} mutationMode="pessimistic"  {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <LinkField source="slug" pathPrefix="articles/" />
            <TextInput source="id" readOnly fullWidth />
            <WidenImageInput source="image" format="externalId" width="300" height="170" />
            <TextInput source="title" resettable validate={[required(), maxLength(250)]} fullWidth />
            <SlugInput source="slug" parentSource="title" disabledSource="status" resettable validate={[required(), validateSlug]} fullWidth />
            <TextInput source="description" resettable validate={maxLength(500)} fullWidth />
            <DateInput source="publishDate" label="Published" validate={required()} />
            <BooleanInput source="status" label="Active" />
            <RichTextInput source="content" label="" fullWidth />
        </SimpleForm>
    </Edit>
);