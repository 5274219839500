import { Box } from '@mui/material';
import HeightIcon from '@mui/icons-material/Height';
import { DividerType } from './';

export const Divider = ({
    dividerType = 'horizontal-rule',
}: DividerType) => {

    return (
        <>
            {dividerType === 'horizontal-rule' &&
                <Box component="hr" sx={{
                    opacity: 0.5,
                    marginTop: '32.5px',
                    marginLeft: '20px',
                    marginRight: '20px'
                }} />
            }
            {dividerType === 'space' &&
                <HeightIcon sx={{
                    display: 'block',
                height: '40px',
                width: '40px',
                    opacity: 0.5,
                    marginTop: '12.5px',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
            }
        </>
    )
};