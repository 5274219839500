import { Box } from '@mui/material';
import { CardType } from './';
import { WidenImage } from '../widen';

export const Card = ({
    image,
    title,
    subtitle,
    description,
}: CardType) => {
    return (
        <Box sx={{ maxWidth: '350px', backgroundColor: '#4d4d4d', boxShadow: '5px 5px 20px #00000066' }}>
            {image &&
                <WidenImage externalId={image.externalId} filename={image.filename} width={350} height={250}/>
            }
            <Box sx={{ padding: '20px', paddingTop: '20px', textAlign: 'center' }}>
                <Box component="h3" sx={{ fontSize: 16, fontWeight: 'normal' }}>{subtitle}</Box>
                <Box component="h2" sx={{ color: (theme: any) => theme.palette.secondary.light }}>{title}</Box>
                <p>{description}</p>
            </Box>
        </Box>
    );
};