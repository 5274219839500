import React from 'react';

const CircularProgress = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width="50"
      height="50"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="35"
        fill="none"
        stroke="#0099e5"
        strokeDasharray="164.93361431346415 56.97787143782138"
        strokeWidth="10"
      >
        <animateTransform
          attributeName="transform"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  );
}

const Container = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 'calc(100vh-10px)',
    width: 'calc(100vw-10px)',
    backgroundColor: '#303030',
    overflow: 'hidden',
};

const Card = {
    marginTop: '25vh',
    backgroundColor: '#424242',
};

const Content = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px',
    color: 'white',
};

const Message = {
    marginTop: '20px',
};

export const FullPageProgress = ({ message }: { message: string }) => (
    <div style={Container}>
        <div style={Card}>
            {
            // @ts-ignore
            <div style={Content}>
                <CircularProgress />
                <h5 style={Message}>{message}</h5>
            </div>
        }
        </div>
    </div>
);