const host = window.location.host;
const protocol = window.location.protocol;

const settings = {
    cmsTitle: 'SCPRT CMS',
    publicSite: `https://${(host === 'manage.scprt.com') ? host.replace('manage.', 'www.') : host.replace('manage.', '')}/`,
    linkExcludeDomains: /^(scprt.com|www.scprt.com|manage.scprt.com)|(scprtdev.com)$/,  // allow other subdomains
    widenAssetGroup: 'SCPRT.com',
    widenCategory: 'SCPRT.com',
    cachePath: '/api/cache',
    loginPath: '/Identity/Account/Login',
    logoutPath: '/Identity/Account/Logout',
    managePath: '/Identity/Account/Manage',
    identityPath: '/api/identity',
    identityStoreName: 'identity',
    legacyIdentityStoreName: `oidc.user:${protocol}//${host}:manage.scprt.com`
}

export default settings;